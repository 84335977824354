import styled, { css } from 'styled-components';
import { StarIc } from '@dsch/dd-icons';

import { Link } from 'components/Toolkit/Button/Link';
import { media } from 'helpers/breakpoints';

import type { Variant } from 'components/PageWrappers/AdDetails/components/GoogleReviews/InfoTitleStarRating/InfoTitleStarRating.typed';

interface VariantProps {
  variant?: Variant;
}

const Wrapper = styled.div`
  display: flex;

  @media (min-width: 320px) and (max-width: 359px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const RatingContainer = styled.div<VariantProps>`
  ${({ theme, variant }) =>
    variant === 'LARGE' ? theme.fontSize.M14 : theme.fontSize.B12};
  display: flex;
  align-items: center;
  gap: 4px;
`;

const RatingGroup = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

interface RatingStyledProps {
  styleAsLink?: boolean;
}

const RatingCSS = css<RatingStyledProps>`
  font-weight: ${({ theme, styleAsLink }) =>
    styleAsLink ? theme.fontWeight.semibold : theme.fontWeight.bold};

  ${media.large} {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`;

const Rating = styled.span<RatingStyledProps>`
  color: ${({ theme, styleAsLink }) =>
    styleAsLink ? '' : theme.colors.GREY_DARKER};
  ${RatingCSS}

  ${media.large} {
    color: ${({ theme }) => theme.colors.GREY_DARKER};
  }
`;

const MaxRating = styled.span<RatingStyledProps>`
  color: ${({ theme, styleAsLink }) =>
    styleAsLink ? '' : theme.colors.GREY_DARK};
  ${RatingCSS}

  ${media.large} {
    color: ${({ theme }) => theme.colors.GREY};
  }
`;

const SStarIc = styled(StarIc)<VariantProps>`
  vertical-align: middle;
  margin-left: ${({ theme }) => theme.spacing.S4};

  ${media.large} {
    margin-left: ${({ theme, variant }) =>
      variant === 'LARGE' ? theme.spacing.S8 : theme.spacing.S4};
  }

  @media (min-width: 320px) and (max-width: 359px) {
    margin-left: 0;
  }
`;

const SLink = styled(Link)`
  color: ${({ theme }) => theme.colors.BLUE};

  ${media.large} {
    pointer-events: none;
    cursor: default;
  }
`;

export {
  Wrapper,
  RatingContainer,
  RatingGroup,
  Rating,
  MaxRating,
  SStarIc,
  SLink,
};
