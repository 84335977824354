import { useRouter } from 'next/router';

type ParamsType = { [key: string]: string | string[] };

export const cleanParams = (params: ParamsType) => {
  const newParams = Object.assign({}, params);
  for (let key in newParams) {
    const isArray = Array.isArray(newParams[key]);

    if (isArray) {
      newParams[key] = (newParams[key] as string[]).filter(
        (item) => item.trim() !== '',
      );
    }
  }
  return newParams;
};

const useNextRouter = () => {
  const router = useRouter();

  const pathSearch = (path: string) => router.asPath.search(path) !== -1;

  const updateUrlByQuery = (
    params: ParamsType,
    shallow: boolean = true,
    clean: boolean = true,
  ) => {
    return router.push(
      {
        pathname: router.asPath.split('?')[0],
        query: clean ? cleanParams(params) : params,
      },
      undefined,
      { shallow },
    );
  };

  const updateUrlByHref = (
    href: string,
    as: string | undefined = undefined,
    shallow: boolean = true,
  ) => {
    router.push(href, as, { shallow });
  };

  return {
    router,
    updateUrlByQuery,
    updateUrlByHref,
    pathSearch,
  };
};

export { useNextRouter };
