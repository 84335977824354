const TDP_SECTIONS = [
  'cars',
  'tractors',
  'plantmachinery',
  'machinery',
  'silagetrailers',
  'silageharvesters',
  'silagewrappers',
  'silagegrabs',
  'balers',
  'combineharvesters',
  'fertiliserspreaders',
  'manurespreaders',
  'agitators',
  'ploughs',
  'harrows',
  'rotovators',
  'rollers',
  'toppers',
  'haybobs',
  'mowers',
  'hedgecutters',
  'cattletrailers',
  'milkingparlours',
  'tractortyres',
  'tractorparts',
  'otherfarmmachinery',
  'farmproduce',
  'farmersnoticeboard',
  'farmservices',
  'farmsheds',
  'farmtools',
  'feedingequipment',
  'fencingequipment',
  'fertilisers',
  'otherfarming',
  'vintagemachinery',
];

const renderTrustedDealerInSection = (sectionName: string) => {
  if (TDP_SECTIONS.includes(sectionName)) {
    return true;
  }
  return false;
};

export { renderTrustedDealerInSection };
