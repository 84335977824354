import styled from 'styled-components';
import { CameraOutlineIc } from '@dsch/dd-icons';

import { BlackBoxBase } from 'components/Toolkit/Tags/styles/mediaStyles';

export interface MediaCountProps {
  mediaCount?: number;
  className?: string;
}

const Camera = styled(CameraOutlineIc)`
  margin-right: ${({ theme }) => theme.spacing.S4};
`;

const Count = styled.div`
  ${BlackBoxBase};
  padding: ${({ theme }) => `${theme.spacing.S4} ${theme.spacing.S8}`};
  ${({ theme }) => theme.fontSize.B12};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

function MediaCount({ mediaCount = 1, className }: MediaCountProps) {
  return (
    <Count className={className} data-testid="media-count">
      <Camera width="16" height="16" /> 1 / {mediaCount}
    </Count>
  );
}

export { MediaCount };
