import * as Styled from 'components/PageWrappers/SharedComponents/TrustedDealerBadge/TrustedDealerBadge.styled';

import type { TrustedDealerBadgeProps } from 'components/PageWrappers/SharedComponents/TrustedDealerBadge/TrustedDealerBadge.typed';

import { pushToDataLayer } from 'services/gtmService';

const TrustedDealerBadge = (props: TrustedDealerBadgeProps) => {
  const { icon, pageName } = props;

  const handleClick = () => {
    pushToDataLayer({
      event: 'trusted_dealer_badge_selected',
      page_name: pageName.toLowerCase(),
    });
  };

  return (
    <Styled.TrustedDealerBadgeContainer onClick={handleClick}>
      {icon && <span>{icon}</span>}
      <Styled.TrustedDealerBadgeText>
        Trusted Dealer
      </Styled.TrustedDealerBadgeText>
    </Styled.TrustedDealerBadgeContainer>
  );
};

export { TrustedDealerBadge };
