import { formatSellerType } from 'helpers/formatting';
import type { InfoTitleStarRatingProps } from 'components/PageWrappers/AdDetails/components/GoogleReviews/InfoTitleStarRating/InfoTitleStarRating.typed';
import * as Styled from 'components/PageWrappers/AdDetails/components/GoogleReviews/InfoTitleStarRating/InfoTitleStarRating.styled';
import { theme } from 'helpers/Theme';

function handleRatingClick(clickTargetElementId: string) {
  const offsetTop = -48;
  const element = document.getElementById(`${clickTargetElementId}`);

  if (element) {
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + offsetTop;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}

function InfoTitleStarRating({
  rating,
  maxRating = 5,
  clickTargetElementId = 'google-dealer-reviews',
  isDealer,
  position = 'MAIN',
  franchiseType,
  county,
  isTrader,
  verification,
  type,
  ratingGroupDataTracking,
  variant = 'LARGE',
  styleAsLink,
  isFakeLink = false,
}: InfoTitleStarRatingProps) {
  return (
    <Styled.Wrapper>
      {/* This logic is from the listItem component in
      `components/Toolkit/MiniUserProfile/MiniUserProfile.tsx`. This allows us to create one component with both the desired text and rating component. */}
      {isDealer
        ? position === 'MAIN'
          ? [franchiseType]
          : [county]
        : [county, formatSellerType(isTrader, verification, type)]}{' '}
      {rating && (
        <Styled.RatingContainer variant={variant}>
          <Styled.SStarIc
            color={theme.colors.ORANGE}
            width="16"
            height="16"
            variant={variant}
            data-testid="star-icon"
          />
          <Styled.RatingGroup>
            <Styled.SLink
              onClick={() => handleRatingClick(clickTargetElementId)}
              inline
              data-tracking={ratingGroupDataTracking}
              isFakeLink={isFakeLink}
            >
              <Styled.Rating styleAsLink={styleAsLink}>{rating}</Styled.Rating>
              <Styled.MaxRating styleAsLink={styleAsLink}>
                /{maxRating}
              </Styled.MaxRating>
            </Styled.SLink>
          </Styled.RatingGroup>
        </Styled.RatingContainer>
      )}
    </Styled.Wrapper>
  );
}

export { InfoTitleStarRating };
