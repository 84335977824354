import { ManufacturerApproved } from 'components/Toolkit/Tags/ManufacturerApproved';
import { CardPaymentTag } from 'components/Toolkit/Tags/PreBuilt/CardPaymentTag';
import { DeliveryAvailableTag } from 'components/Toolkit/Tags/PreBuilt/DeliveryAvailableTag';
import { HistoryCheckTag } from 'components/Toolkit/Tags/PreBuilt/HistoryCheckTag';
import { WarrantyTag } from 'components/Toolkit/Tags/PreBuilt/WarrantyTag';
import { Tag } from 'components/Toolkit/Tags/Tag';
import { GreenLightTag } from 'components/Toolkit/Tags/PreBuilt/GreenlightTag';
import { WantedTag } from 'components/Toolkit/Tags/PreBuilt/WantedTag';
import styled from 'styled-components';
import { media } from 'helpers/breakpoints';

interface Merits {
  description?: string;
  colour?: string;
  text?: string;
  field?: string;
}

export interface ICardPayment {
  stripeAccountId?: string;
  quantity?: string;
  shippingFee?: string;
  shippingAvailable?: boolean;
  cardPaymentType?: string;
  acceptPayment?: boolean;
}

type TrustIndicatorTagsProps = {
  manufacturerApprovedBadge?: { badgeUrl?: string; manufacturer?: string };
  meritsV2?: Merits[];
  specialOffer?: string;
  greenlightVerified?: boolean;
  cardPayments?: ICardPayment[];
  deliveryAvailable: boolean;
  wanted?: boolean;
};

const TagList = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing.S8};
`;

const TagListItem = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing.S4};
  margin-right: ${({ theme }) => theme.spacing.S8};

  ${media.large} {
    margin-bottom: ${({ theme }) => theme.spacing.S8};
  }
`;

function TrustIndicatorTags(props: TrustIndicatorTagsProps) {
  const {
    wanted,
    deliveryAvailable,
    cardPayments,
    meritsV2,
    specialOffer,
    greenlightVerified,
    manufacturerApprovedBadge,
  } = props;

  let tags = [];

  if (wanted) {
    tags.push(<WantedTag />);
  } else {
    if (greenlightVerified) {
      tags.push(<GreenLightTag />);
    }

    if (meritsV2) {
      const warrantyMerit = meritsV2.find(
        (merit) => merit.field === 'warranty',
      );
      if (warrantyMerit) {
        tags.push(<WarrantyTag text={warrantyMerit.text} />);
      }
    }

    if (manufacturerApprovedBadge) {
      tags.push(
        <ManufacturerApproved
          manufacturer={manufacturerApprovedBadge.manufacturer ?? ''}
        />,
      );
    }

    if (meritsV2) {
      const containsHistoryCheck = meritsV2.filter(
        (merit) => merit.field === 'historyChecked',
      );
      if (containsHistoryCheck.length === 1) {
        tags.push(<HistoryCheckTag />);
      }
    }

    if (deliveryAvailable) {
      tags.push(<DeliveryAvailableTag />);
    }

    if (cardPayments && cardPayments[0].acceptPayment) {
      tags.push(<CardPaymentTag />);
    }

    if (specialOffer) {
      tags.push(<Tag text={specialOffer} textColor="GREY_DARK" />);
    }
  }

  if (tags.length === 0) {
    return null;
  }

  return (
    <TagList>
      {tags.map((tag, index) => (
        <TagListItem key={index}>{tag}</TagListItem>
      ))}
    </TagList>
  );
}

export { TrustIndicatorTags };
