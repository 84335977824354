import styled from 'styled-components';
import { HeartOutlineIc } from '@dsch/dd-icons';
import { GhostButton } from 'components/Toolkit/Button/GhostButton';
import { MouseEvent, useEffect, useState } from 'react';
import { media } from 'helpers/breakpoints';

type Variant = 'OVERLAY' | 'DEFAULT';

export interface SaveAdProps {
  isAdSaved?: boolean;
  toggleSave?: (event: MouseEvent) => void;
  isUnderlined?: boolean;
  hideText?: boolean;
  variant?: Variant;
  className?: string;
  iconSizeDesktop?: 'SM' | 'MD';
}

type TSHeartOutlineIc = {
  $isAdSaved?: boolean;
  $variant: Variant;
  $iconDimensionDesktop?: number;
};

const Action = styled(GhostButton)`
  overflow: hidden;
`;

const animationDuration = 200;
const Text = styled.span<{ isAdSaved?: boolean }>`
  ${({ theme }) => theme.fontSize.M16}
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  display: none;
  overflow: hidden;

  ${media.medium} {
    display: inline;
    width: ${({ isAdSaved }) => (isAdSaved ? '43px' : '34px')};
    transition: width ${animationDuration}ms ease-in-out;
  }
`;

const SHeartOutlineIc = styled(HeartOutlineIc)<TSHeartOutlineIc>`
  ${media.medium} {
    width: ${({ $iconDimensionDesktop }) => `${$iconDimensionDesktop}px`};
    height: ${({ $iconDimensionDesktop }) => `${$iconDimensionDesktop}px`};
  }

  path {
    fill: ${({ theme, $isAdSaved, $variant }) =>
      $isAdSaved
        ? theme.colors.RED
        : $variant === 'OVERLAY'
        ? `${theme.colors.BLACK}3D`
        : 'transparent'};
    stroke: ${({ theme, $isAdSaved, $variant }) =>
      $isAdSaved
        ? theme.colors.RED
        : $variant === 'OVERLAY'
        ? theme.colors.WHITE
        : theme.colors.GREY_DARKER};
  }
`;

function SaveAd({
  isAdSaved,
  toggleSave,
  hideText,
  variant = 'DEFAULT',
  iconSizeDesktop,
  className,
}: SaveAdProps) {
  const iconDimensionDesktop = iconSizeDesktop === 'SM' ? 20 : 24;
  const [text, setText] = useState(isAdSaved ? 'Saved' : 'Save');

  function handleClick(event: MouseEvent) {
    toggleSave && toggleSave(event);
  }

  useEffect(() => {
    if (isAdSaved) {
      setText('Saved');
    } else {
      setTimeout(() => {
        setText('Save');
      }, animationDuration);
    }
  }, [isAdSaved]);

  return (
    <Action
      onClick={handleClick}
      size="SMALL"
      data-testid="save-ad"
      data-tracking="ad-save-this-ad"
      className={className}
    >
      <SHeartOutlineIc
        data-testid="save"
        $iconDimensionDesktop={iconDimensionDesktop}
        $isAdSaved={isAdSaved}
        $variant={variant}
      />
      {!hideText && (
        <Text isAdSaved={isAdSaved} data-testid="save-ad-text">
          {text}
        </Text>
      )}
    </Action>
  );
}

export { SaveAd };
