import { useUserContext } from 'contexts/UserContext';
import { useAuthOptions } from 'hooks/useAuthOptions';
import * as brazeService from 'services/brazeService';

type WrappedFunctions = typeof brazeService;

/**
 * This hook will expose any functions exported from `brazeService`, which will only be invoked if the user has consented to braze
 * @returns Braze functions
 */
const useBraze = () => {
  const {
    consentOptions: { allowBraze = false },
  } = useUserContext();
  const { isAuthenticated } = useAuthOptions();
  const CONDITIONS_TO_ENABLE_BRAZE = isAuthenticated && allowBraze;

  const ifAllowBraze =
    <T extends (...args: unknown[]) => any>(callback: T) =>
    (...args: Parameters<T>) => {
      if (CONDITIONS_TO_ENABLE_BRAZE) callback(...args);
    };

  const functions = Object.keys(brazeService).reduce(
    (acc: WrappedFunctions, functionKey: keyof typeof brazeService) => {
      acc[functionKey] = ifAllowBraze(brazeService[functionKey]);
      return acc;
    },
    {} as WrappedFunctions,
  );

  return functions;
};

export { useBraze };
