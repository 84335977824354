import type { formatMetaDataArgs } from 'components/ToolkitV2/CardV2/helpers/formatMetaData/formatMetaData.typed';

const formatMetaData = (args: formatMetaDataArgs) => {
  const { metaData } = args;

  let formattedMetaData: (string | undefined)[] = [];

  metaData.forEach((item) => {
    if (item) {
      formattedMetaData.push(item);
    }
  });

  return formattedMetaData;
};

export { formatMetaData };
