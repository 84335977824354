import styled from 'styled-components';

import { HeaderStyledProps } from 'components/ToolkitV2/CardV2/enhancements/WithHeader/WithHeader.typed';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div<HeaderStyledProps>`
  display: ${({ isGrid }) => (isGrid ? 'none' : 'flex')};
  background: ${({ theme }) => theme.colors.WHITE};
  padding: ${({ theme }) => `${theme.spacing.S8} ${theme.spacing.M16}`};
  align-items: center;
  ${({ theme, showBorder }) =>
    showBorder && `border-bottom: 1px solid ${theme.colors.GREY_LIGHT}`};
  justify-content: space-between;
`;

const Children = styled.div`
  flex: 1;
`;

export { Container, Header, Children };
