import { CheckmarkDoneIc } from '@dsch/dd-icons';

import { Tag } from 'components/Toolkit/Tags/Tag';

function GreenLightTag() {
  return (
    <Tag
      text="Greenlight"
      textColor="GREY_DARK"
      bgColor="GREY_LIGHTER"
      icon={<CheckmarkDoneIc color={'GREEN'} />}
      iconPosition="LEFT"
    />
  );
}

export { GreenLightTag };
