import styled from 'styled-components';
import { media } from 'helpers/breakpoints';

const TrustedDealerBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 0 auto;
`;

const TrustedDealerBadgeText = styled.div`
  display: none;

  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARK};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-left: ${({ theme }) => theme.spacing.S8};

  ${media.medium} {
    display: block;
  }
`;

export { TrustedDealerBadgeContainer, TrustedDealerBadgeText };
