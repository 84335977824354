import styled from 'styled-components';
import { BaseTagStyles } from 'components/Toolkit/Tags/styles/baseTagStyles';

export interface ManufacturerApprovedProps {
  manufacturer: string;
}

const Tag = styled.div<any>`
  ${BaseTagStyles};
  color: ${({ theme }) => theme.colors.GREY_DARK};
  background: ${({ theme }) => theme.colors.GREY_LIGHTER};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

function ManufacturerApproved({ manufacturer }: ManufacturerApprovedProps) {
  return <Tag>{manufacturer} Approved</Tag>;
}

export { ManufacturerApproved };
