import * as Styled from 'components/ToolkitV2/CardV2/enhancements/WithHeader/WithHeader.styled';

import type { WithHeaderProps } from 'components/ToolkitV2/CardV2/enhancements/WithHeader/WithHeader.typed';

function WithHeader(props: WithHeaderProps) {
  const { children, isGrid, elements, showBorder = true, className } = props;

  const displayHeader = Boolean(elements);

  return (
    <Styled.Container>
      {displayHeader && (
        <Styled.Header
          className={className}
          isGrid={isGrid}
          showBorder={showBorder}
        >
          {elements}
        </Styled.Header>
      )}
      <Styled.Children>{children}</Styled.Children>
    </Styled.Container>
  );
}

export { WithHeader };
